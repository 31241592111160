.story {
  padding-top: rem(120);

  @include mq(desktop) {
    padding-top: 0;
    margin-top: rem(136);
  }
}

.story__heading {
  @include mq(desktop) {
    padding-top: rem(56);
    padding-bottom: rem(56);
  }
}

.story__media {
  position: relative;
  z-index: 1;
}

.story__gallery {
  width: 100%;

  @include aspect-ratio(704, 456);

  @include mq(desktop) {
    position: absolute !important;
    top: calc(100% + #{rem(40)});
    z-index: 2;
    transform: translateY(-50%);

    @include aspect-ratio(556, 456);
  }
}

.story__body {
  height: rem(320);
  position: relative;
  padding-top: calc(15vw + #{rem(48)});
  padding-bottom: rem(48);
  margin-top: -15vw;

  @include mq(tablet) {
    height: rem(336);
  }

  @include mq(desktop) {
    height: rem(304);
    padding-top: rem(76);
    padding-bottom: rem(76);
    margin-top: 0;
  }

  .story--primary &,
  .story--secondary & {
    color: color('white');
  }

  .story--primary & {
    background-color: color('primary');
    background-image: gradient('primary');
  }

  .story--secondary & {
    background-color: color('secondary');
    background-image: gradient('secondary');
  }

  .container, .row, .col {
    height: 100%;
  }

  .col {
    position: relative;
  }
}

.story__fragment {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &:not(:first-of-type) > * {
    opacity: 0;
    visibility: hidden;
  }
}

.story__polygon {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;

  @include mq(tablet) {
    height: 100%;
  }

  img {
    width: auto;
    height: auto;

    @include mq(tablet) {
      height: 100%;
    }
  }
}

.story__polygon--left {
  @include mq(desktop) {
    right: auto;
    left: 0;
  }
}
