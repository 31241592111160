.carousel--small {

  .perche-scegliere & {
    padding-top: rem(56);

    figure {
      margin-bottom: rem(76);
    }

    h3 {
      color: color('primary');
      font-weight: 500;
      margin-bottom: rem(32);
    }

    .p {
      text-align: center;
      max-width: rem(500);
      color: color('grey');
    }
  }
}

.hero--with-buttons {

  .perche-scegliere & {

    @include mq(desktop) {
      min-height: 100vh;
    }
  }
}

.header .nav,
.rounded-block,
.help-block,
.footer__signature,
.footer__logo-link {

  .perche-scegliere & {
    display: none;
  }
}

.footer {

  .perche-scegliere & {
    margin-top: 0;

    &:before {
      display: none;
    }
  }
}

.footer__extra {

  .perche-scegliere & {
    display: block;
  }
}
