.nav {
  padding-right: rem(30);

  @include mq(tablet) {
    display: flex;
    align-items: center;
  }

  @include mq(desktop) {
    padding-right: 0;
  }

  @include mq(wide) {
    padding-right: rem(30);
  }
}

.nav__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem(16);
  line-height: 1;
  cursor: pointer;
  background: transparent;
  border: 0;
  font-family: 'SkyText';

  .footer__menu & {
    color: color('grey');
  }

  @include mq(desktop) {
    font-size: rem(12);
  }

  @include mq(desktop-large) {
    font-size: rem(14);
  }

  @include mq(wide) {
    font-size: rem(16);
  }

  &:nth-child(n+2) {
    margin-top: rem(16);

    @include mq(tablet) {
      margin-top: 0;
      margin-left: rem(32);
    }

    @include mq(desktop) {
      margin-left: rem(25);
    }

    @include mq(wide) {
      margin-left: rem(32);
    }
  }

  &.active > .link::after {
    transform-origin: 0% 50%;
    transform: none;
  }

  @media (pointer: fine) {
    &:hover > .link::after {
      transform-origin: 0% 50%;
      transform: none;
    }
  }

  // &[href="/soluzioni/strategia.html"] {
    &[href="/soluzioni/comportamento-spesa.html"] {

    @include mq(desktop) {
      padding-left: rem(32);
      position: relative;

      &:after {
        content:'';
        display: block;
        width: rem(1);
        height: rem(16);
        background-color: color('grey-light');
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
