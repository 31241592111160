.button {
  @extend %button;
  min-width: rem(152);
  padding: rem(12) rem(40);
  background-color: color('white');
  border: solid 1px color('white');
  border-radius: rem(20);
  font-size: rem(15.2);
  font-weight: 500;
  line-height: 1;
  color: color('primary');
  transition-property: background-color, border-color, color, opacity;
  transition-duration: duration();
  cursor: pointer;

  &:hover {
    background-color: color('primary');
    color: color('white');
  }

  &.button-filled {
    background-color: color('primary');
    background-image: linear-gradient(90deg,#0085ff,#0044b6);
    text-align: center;
    display: flex;
    color: white;

    &-2 {
      @extend .button-filled;
      background-image: gradient('secondary');
    }
    &[disabled] {
      opacity: 0.1;
    }
  }
}

.button--primary {
  background-color: color('primary');
  border-color: color('primary');
  color: color('white');
  transition-property: background-position, border-color, color, opacity;
  transition-timing-function: linear, ease, ease, ease;
  background-size: 400%;
  background-position: 99% 0;
  background-image: linear-gradient(
      90deg
    , #ffffff 26%, #0085ff 75%,#0044b6);

  &:hover {
    background-position: 0 0;
    background-color: color('white');
    color: color('primary');
  }
}

.button--secondary {
  color: color('secondary');

  &:hover {
    background-color: color('secondary');
    color: color('white');
  }
}

.button--outline {
  border-color: color('primary');
  background-color: color('primary');
  color: color('white');
  transition-property: background-position, border-color, color, opacity;
  transition-timing-function: linear, ease, ease, ease;
  background-size: 400%;
  background-position: 99% 0;
  background-image: linear-gradient(
      90deg
    , #ffffff 26%, #0085ff 75%,#0044b6);

  &-2 {
    @extend .button--outline;
    background-image: none;
    background-color: transparent;
    color: color('primary');
  }

  &:hover {
    background-position: 0 0;
    background-color: color('white');
    color: color('primary');
  }
}

.button--whiteline {
  background-color: transparent;
  border-color: color('white');
  color: color('white');

  &:hover {
    background-color: color('white');
    color: color('primary');
  }
}

.button--white {
  background-color: color('white');
  border-color: color('white');
  color: color('primary');

  &:hover {
    background-color: transparent;
    color: color('white');
  }
}

.button--empty {
  background-color: transparent;
  border-color: transparent;
  color: inherit;

  &:hover {
    background-color: color('primary');
    border-color: color('primary');
    color: color('white');
  }

  &.button--outline {
    border-color: currentColor;

    &:hover {
      background-color: color('white');
      border-color: color('white');
      color: color('primary');
    }
  }
}

.button__label {
  flex-grow: 1;
  text-transform: uppercase;
  text-align: center;

  .button__icon + & {
    margin-left: rem(13);
  }
}

.sides-3 > *:not(:last-child) {
  margin-right: 0;
  margin-bottom: 2.5rem;
  @include mq(mobile-large) {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  @include mq(tablet) {
    margin-right: 2.5rem;
  }
}

.faq .accordion__title {
  display: none;
}

.button-circle {
  width: rem(44);
  height: rem(44);
  border-radius: 50%;
  background-color: color('primary');
  background-image: gradient('primary');
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled="true"],
  &:disabled {
    background-color: color('grey-medium');
    background-image: none;
    cursor: not-allowed;
  }

  &.button-circle--prev,
  &.button-circle--next {
    &:after {
      content:'';
      display: block;
      width: rem(9);
      height: rem(9);
    }
  }

  &.button-circle--prev {
    &:after {
      border-left: rem(2) solid color('white');
      border-top: rem(2) solid color('white');
      transform: translateX(10%) rotate(-45deg);
    }
  }

  &.button-circle--next {
    &:after {
      border-right: rem(2) solid color('white');
      border-bottom: rem(2) solid color('white');
      transform: translateX(-10%) rotate(-45deg);
    }
  }

  &.button-circle--icon {
    width: rem(48);
    height: rem(48);
    background: none !important;
    padding: 0;
    border: 0 none;
  }

  &.button-circle--icon-switch {
    & > :first-child {
      display: block;
    }
    & > :last-child {
      display: none;
    }
    &.--active {
      & > :first-child {
        display: none;
      }
      & > :last-child {
        display: block;
      }
    }
  }

  &.button-circle--lg {
    width: rem(64);
    height: rem(64);

    &.button-circle--prev,
    &.button-circle--next {
      &:after {
        width: rem(12);
        height: rem(12);
      }
    }
  }
}
