.partner {
  .hero {
    overflow: visible;

    & + .section {
      @include mq(desktop, desktop-large) {
        padding-top: rem(32);
      }
    }

    .row {
      position: relative;
    }
  }

  .help-block {
    display: none;
  }

  .hero__background {
    @include mq(tablet, desktop) {
      height: 75%;
    }
  }

  .hero__content {
    @include mq(tablet, desktop) {
      padding: rem(104) 0 rem(8);
    }

    @include mq(desktop) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.partner__logos {
  width: 100%;
  max-width: rem(480);
  overflow: visible;
  margin: 0 auto rem(32);

  @include mq(tablet, desktop) {
    margin-bottom: 0;
    max-width: rem(480);
  }

  @include mq(desktop) {
    margin: 0;
    padding-bottom: rem(80);
    max-width: 100%;
  }

  .logo-box {
    &:first-child {
      margin-right: 0;
      margin-left: auto;

      @include mq(desktop) {
        margin-right: auto;
        margin-left: 0;
      }
    }

    &:last-child {
      margin-top: rem(-8);
      margin-right: auto;
      margin-left: 0;

      @include mq(desktop) {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

.form-block,
.rounded-block {
  background-color: color('white');
  z-index: 4;
  position: relative;
}
