.rounded-block,
.help-block {

  .privacy &,
  .cookies &,
  .condizioni-generali &,
  .faq & {
    display: none;
  }
}

.footer {

  .privacy &,
  .cookies &,
  .condizioni-generali &,
  .faq & {
    margin-top: 0;

    &:before {
      display: none;
    }
  }
}
