[data-reveal],
[data-reveal-group] > * {
  transition-property: transform, opacity, visibility;
  transition-duration: duration(2);
}

[data-reveal="slideIn"],
[data-reveal-group="slideIn"] > * {
  transform: translate3d(0, 10vh, 0);
  opacity: 0;
  visibility: hidden;
}

[data-reveal="slideInRight"],
[data-reveal-group="slideInRight"] > * {
  transform: translate3d(-50vw, 0, 0);
  transition-duration: duration(2.5);
}

[data-reveal="slideInLeft"],
[data-reveal-group="slideInLeft"] > * {
  transform: translate3d(50vw, 0, 0);
  transition-duration: duration(2.5);
}

[data-reveal].revealed,
[data-reveal-group] > *.revealed {
  transform: none;
  opacity: 1;
  visibility: visible;
}
