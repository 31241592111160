.anchored-sections {
  padding-top: rem($header-size-mobile);
  position: relative;

  @include mq(tablet) {
    padding-top: rem($header-size-tablet);
  }

  @include mq(desktop) {
    padding-top: rem($header-size-desktop);
  }
}

.anchored-sections__nav {
  display: flex;
  width: 100%;
  height: rem($header-size-mobile);
  overflow-x: scroll;
  padding: 0 rem(20);
  flex-wrap: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  justify-content: flex-start;
  align-items: center;
  background-color: color('white');
  box-shadow: $shadow;
  white-space: nowrap;

  @include mq(tablet) {
    height: rem($header-size-tablet);
    padding: 0;
    justify-content: center;
  }

  @include mq(desktop) {
    overflow-x: hidden;
    height: rem($header-size-desktop);
  }

  @include mq($until: tablet) {
    &::after {
      display: block;
      width: rem(20);
      height: 1em;
      flex-shrink: 0;
      content: '';
    }
  }

  &.sticky {
    position: fixed;
    top: rem($header-size-mobile);

    @include mq(tablet) {
      top: rem($header-size-tablet);
    }

    @include mq(desktop) {
      top: rem($header-size-desktop);
    }
  }

  .nav__item {
    &:nth-child(n+2) {
      margin-top: 0;
      margin-left: rem(32);
    }
  }
}
