.fixed-banner {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  transition: all .5s ease-in-out;
  display: none;

  @include mq(tablet) {
    display: block;
  }

  &.is-small {
    width: rem(442);
    bottom: rem(20);
    transition: all .5s ease-in-out;
  }

  &.is-hide {
    transform: translateY(calc(100% + #{rem(20)}));
    transition: all .5s ease-in-out;
  }
}

.fixed-banner__close {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: rem(28);
  z-index: 3;
  transition: all .5s ease-in-out;
  cursor: pointer;

  @include mq(desktop-large) {
    top: rem(40);
  }

  .is-small & {
    top: 0;
    right: rem(16);
    transition: all .5s ease-in-out;

    path {
      stroke: color('black');
    }
  }
}

.fixed-banner__container {
  position: relative;
  padding-right: rem(16);
  padding-left: rem(16);

  @include mq(desktop-large) {
    padding: 0;
  }

  &:after {
    content:'';
    display: block;
    width: 100%;
    height: calc(100% - #{rem(24)});
    background-color: color('secondary');
    background-image: gradient('secondary');
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: all .5s ease-in-out;
  }

  .is-small & {

    &:after {
      width: rem(402);
      height: rem(80);
      border-radius: rem(60);
      transition: all .5s ease-in-out;
    }
  }
}

.fixed-banner__img {

  .is-small & {
    display: none;
  }
}

.fixed-banner__content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  .is-small & {
    justify-content: flex-start;
    width: rem(402);
    height: rem(80);
  }
}

.fixed-banner__text {
  color: color('white');
  text-align: center;
  padding-left: rem(36);
  padding-right: rem(36);
  padding-top: rem(24);
  transition: all .5s ease-in-out;

  .is-small & {
    flex: 1;
    padding: rem(16) rem(16) rem(16) rem(32);
    transition: all .5s ease-in-out;
  }

}

.fixed-banner__title {

  .is-small & {
    font-size: rem(24);
    /* line-height: rem(48); */
    line-height: 1;
  }
}

.fixed-banner__subtitle {

  .is-small & {
    display: none;
  }
}

.fixed-banner__button {
  padding-top: rem(24);

  .is-small & {
    padding-top: 0;
    padding-right: rem(32);
    flex-shrink: 0;
  }
}
