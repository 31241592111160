.rounded-block {

  body[data-ctrl="404"] & {
    display: none;
  }
}

.post-rounded-block {
  border: 1px solid #D9D9D9;
  border-radius: rem(16);
  overflow: hidden;
  margin-bottom: rem(72);

  .h2 {
    line-height: rem(40);
  }

  .columns-grid--two & {

    @include mq(desktop) {
      flex-basis: calc(50% - #{rem(50)});
    }
  }

  .columns-grid--three & {

    @include mq(desktop) {
      flex-basis: calc(33.3333% - #{rem(45)});
    }
  }
}

.post-rounded-block__figure {
  overflow: hidden;
  height: rem(248);
}

.post-rounded-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-rounded-block__content {
  padding: rem(20) rem(30);

  .link {
    color: color('primary');
    font-weight: 500;
  }

  .category {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    color: #828282;
    .cat-name {
      font-weight: 500;
      color: color('primary');
    }
  }
}
