.hero {
  display: flex;
  min-height: rem(240 + $header-size-mobile);
  padding-top: rem($header-size-mobile);
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;

  @include mq(tablet) {
    min-height: rem(240 + $header-size-tablet);
    padding-top: rem($header-size-tablet);
  }

  @include mq(desktop) {
    min-height: rem(240 + $header-size-desktop);
    padding-top: rem($header-size-desktop);
  }

  .mobile-hyperlocal &,
  .display-banner &,
  .radio &,
  .digital-video &,
  .addressable-tv &,
  .strategia & {

    @include mq(tablet) {
      min-height: rem(250 + $header-size-tablet);
    }

    @include mq(desktop) {
      min-height: rem(250 + $header-size-desktop);
    }
  }

  .expense-behaviour & {
    @include mq(tablet) {
      min-height: rem(400 + $header-size-tablet);
    }

    @include mq(desktop) {
      min-height: rem(400 + $header-size-desktop);
    }
  }

  &--card {
    padding: 112px 90px;
  }
}

.hero--full {
  height: auto;
  min-height: 0;

  @include mq(desktop) {
    height: 100vh;
    max-height: rem(760);
    min-height: rem(656);
  }
}

.hero--primary {
  background-color: color('primary');
  background-image: gradient('primary');
  color: color('white');
}

.hero.hero--in-page {
  border-radius: 24px;
  padding: 0;

  @include mq(tablet) {
    min-height: rem(416);
  }

  @include mq(desktop) {
    min-height: rem(416);
  }

  .hero__content {
    width: 100%;
    padding: 10%;
  }
}

.hero--with-buttons {
  background-color: color('primary');
  background-image: gradient('primary');
  color: color('white');
}

.hero__background {
  width: 100%;
  height: 100%;

  .hero:not(.hero--featured) & {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .hero--featured & {
    @include mq(desktop) {
      width: auto;
      max-height: 55vw;
      max-width: initial;
      position: absolute;
      top: 0;
      z-index: -1;
    }
  }
}

.hero--404 {
  height: calc(100vh - #{rem(312)});
}

.hero__content {
  padding: rem(40) 0 rem(56);

  @include mq(tablet) {
    padding-top: rem(56);
  }

  @include mq(desktop) {
    padding: 0;
  }

  .hero--featured & {
    transform: translateY(#{rem(48)});
    opacity: 0;
    visibility: hidden;
    transition-property: transform, opacity, visibility;
    transition-duration: duration();

    &.revealed {
      transform: none;
      opacity: 1;
      visibility: visible;
    }
  }
}

.bg-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: none;

  img {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: all .5s ease-in-out;
    max-width: 50%;

    &.is-shown {
      opacity: 1;
      transition: all .5s ease-in-out;
    }
  }

  @include mq(desktop) {
    display: block;
  }
}
