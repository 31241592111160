/*=========*/
/* GENERAL */
/*=========*/
* {
  box-sizing: border-box;
}

html {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 100%;
  }

  @media screen and (min-width: 992px) and (max-width: 1023px) {
    font-size: 120%;
  }

  @media screen and (min-width: 1440px) {
    font-size: 100%;
  }
}

body {
  transition-property: opacity, visibility;
  transition-duration: duration();

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: color('primary');
    background-image: gradient('primary');
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: duration();
  }

  &.resizing::before {
    transition-duration: 0s;
    opacity: 1;
    visibility: visible;
  }
}

main {
  overflow: hidden;
}

/*============*/
/* CONTAINERS */
/*============*/
section[id] {
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 0 rem(20);
  position: relative;
  margin: 0 auto;

  @include mq(desktop) {
    padding: 0 rem(24);
    max-width: rem(1440);
  }
}

.container--small {
  @include mq(desktop) {
    max-width: rem(952);
  }
}

.container--smaller {
  @include mq(desktop) {
    max-width: rem(723);
  }
}

.off-container {
  @include off-container;
}

.full-height {
  height: 100%;
}

.media-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row--align-items-center {
  align-items: center !important;
}

/*=============*/
/* GRID SYSTEM */
/*=============*/
@include grid(12, rem(12), $breakpoints);

.row--reverse {
  direction: rtl;

  * {
    direction: ltr;
  }
}

.md\:row--reverse {
  @include mq(desktop) {
    direction: rtl;

    * {
      direction: ltr;
    }
  }
}

/*=================*/
/* UTILITY CLASSES */
/*=================*/
.md\:visible {
  @include mq($until: desktop) {
    display: none !important;
  }
}

.md\:hidden {
  @include mq(desktop) {
    display: none !important;
  }
}

@include framework-rules('m', (
  "property": (
    "t": "margin-top",
    "b": "margin-bottom"
  ),
  "value": (
    0: 0,
    1: rem($scalar),
    2: rem($scalar * 2),
    3: rem($scalar * 3),
    4: rem($scalar * 4),
    5: rem($scalar * 5),
    6: rem($scalar * 6),
    7: rem($scalar * 7),
    8: rem($scalar * 8)
  ),
  "breakpoints": ("md", "lg")
));

.is-desktop {
  display: none !important;
}

.is-mobile {
  display: block !important;
}

@include mq(desktop) {
  .is-desktop {
    display: block !important;
  }
  .is-mobile {
    display: none !important;
  }
}

.columns-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.description-image {
  vertical-align: middle;
  margin-left: 16px;
}

.row {
  &--gap-24 {
    gap: 24px;
  }
  &--on-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr))
  }
  &--items-stretch {
    align-items: stretch;
  }
}

.card-rounded {
  border: 1px solid #c4c4c4;
  border-radius: 18px;
  padding: 24px 24px 40px;
}

.opacity-20 {
  opacity: 0.2;
}

@media (max-width: 767px) {
  .mobile-text-center {
    text-align: center;
  }
}

.video-container {
  position: relative;

  video {
    display: block;
    position: relative;
    z-index: 0;
  }
  .video-controls {
    position: absolute;
    right: 12px;
    bottom: 12px;
    z-index: 10;

    button img {
      width: 48px;
      height: 48px;
    }
  }
}

.gif-container {
  position: relative;

  img {
    display: none;
    position: relative;
    z-index: 0;

    &.gif-image-pause {
      display: block;
    }
  }

  &.--gif-play {
    img {
      display: block;
      &.gif-image-pause {
        display: none;
      }
    }
  }

  .gif-controls {
    position: absolute;
    right: 64px;
    bottom: 26px;
    z-index: 10;

    button img {
      width: 48px;
      height: 48px;
    }
  }
}

.text-white {
  color: color("white") !important;
}
