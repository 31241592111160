.sticky-action {
  width: 100%;
  padding: rem(16) rem(56);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: color('white');
  box-shadow: $shadow;
  text-align: center;

  @include mq(tablet) {
    width: auto;
    min-width: rem(324);
    padding: rem(32) rem(56);
    right: rem(32);
    bottom: rem(32);
    border-radius: rem(56);
  }

  & > .button {
    padding-right: rem(16);
    padding-left: rem(16);
  }
}
