.rounded-banner {
  padding: rem(48) rem(20);
  background-color: color('primary');
  background-image: gradient('primary');
  text-align: center;
  color: color('white');

  @include off-container;

  @include mq(tablet) {
    width: 100%;
    padding-right: rem(72);
    padding-left: rem(72);
    left: auto;
    border-radius: rem(156);
  }

  @include mq(desktop) {
    display: flex;
    padding-right: rem(156);
    padding-left: rem(156);
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  & > .h1 {
    @include mq(desktop) {
      max-width: rem(632);
    }
  }

  & > .button {
    margin-top: rem(48);

    @include mq(desktop) {
      margin-top: 0;
    }
  }
}
