.modal {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  transition-property: opacity, visibility;
  transition-duration: duration();
  opacity: 0;
  visibility: hidden;

  @include mq(desktop) {
    background-color: rgba(255, 255, 255, .85);
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.modal__content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: rem($header-size-mobile + 104) rem(20) rem(104);
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: color('secondary');
  background-image: gradient('secondary');
  color: color('white');

  @include mq(tablet) {
    padding: rem(104) rem(48) rem(72);
    justify-content: space-around;
  }

  @include mq(desktop) {
    max-width: rem(640);
    height: auto;
    min-height: rem(480);
    transform: translate3d(0, 50%, 0);
    justify-content: space-between;
    border-radius: rem($radius);
    box-shadow: $shadow;
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: duration();
  }

  .modal.open & {
    @include mq(desktop) {
      transform: none;
      opacity: 1;
      transition-delay: duration();
    }
  }

  .icon {
    font-size: rem(56);
  }

  p, .p {
    @include mq($from: tablet, $until: desktop) {
      max-width: 60vw;
      margin-right: auto;
      margin-left: auto;
      font-size: rem(20);
      line-height: rem(32);
    }
  }
}

.modal__close {
  width: rem(32);
  height: rem(16);
  position: absolute;
  top: rem(20);
  right: rem(20);
  z-index: 2;
  cursor: pointer;

  @include mq(desktop) {
    top: rem(24);
  }

  &::before,
  &::after {
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background-color: currentColor;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}
