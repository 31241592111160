.row--overview {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;

  @include mq(desktop) {
    text-align: left;
    margin: 0;
    flex-direction: row;
    justify-content: center;
  }
}

.row--overview-1 {
  margin-top: 15.5vw;

  @include mq(desktop) {
    margin-top: rem(120);
  }
}

.row--overview-4 {

  @include mq(desktop) {
    height: rem(315);
  }
}

.visual {
  margin-left: auto;
  margin-right: auto;

  .row--overview-1 & {
    height: rem(418);
  }

  .row--overview-2 & {
    height: rem(286);
  }

  .row--overview-3 & {
    height: rem(396);
  }

  .row--overview-1 & {
    margin-top: rem(104);
    margin-bottom: rem(64);

    @include mq(desktop) {
      margin: 0;
    }
  }

  .row--overview-2 & {
    margin-top: 6.5vw;
    margin-bottom: 6.5vw;

    @include mq(desktop) {
      margin: 0;
    }
  }

  .row--overview-3 & {
    margin-top: 6.5vw;

    @include mq(desktop) {
      margin: 0;
    }
  }

}

.col--visual {

  .row--overview & {
    width: rem(300);

    @include mq(mobile-large) {
      width: rem(400);
    }
  }
}

.col--content {

  .row--overview & {

    @include mq(desktop) {
      width: calc(100% - 488px);
      margin-left: rem(88);
      max-width: rem(554);
    }
  }
}

.interact-image {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @include mq(desktop) {
    align-items: flex-end;
    top: rem(-90);
  }
}

.interact-image__plus {
  width: rem(30);
  height: auto;
  margin: rem(40) 0;

  @include mq(desktop) {
    margin: 0;
    width: rem(38);
    position: absolute;
    top: 0;
    right: rem(110);
  }
}

.interact-image__interact {
  position: relative;

  @include mq(desktop) {
    top: rem(88);
    right: rem(68);
  }
}
