/*=======*/
/* PATHS */
/*=======*/
$assets: '../../static';

/*==========================*/
/* SIZES AND RESPONSIVENESS */
/*==========================*/
$breakpoints: (
  "xs": 320,
  "sm": 768,
  "md": 1024,
  "lg": 1366,
  "xl": 1600
);

$mq-breakpoints: (
  "mobile-large": 480px,
  "tablet": 768px,
  "desktop": 1024px,
  "desktop-large": 1280px,
  "wide": 1440px
);

$scalar: 8;
$radius: 16;

$header-size-mobile: 80;
$header-size-tablet: 80;
$header-size-desktop: 80;

/*===================*/
/* THEMES AND COLORS */
/*===================*/
$colors: (
  "primary": #255CB9,
  "secondary": #8E54E9,
  "black": #000000,
  "grey": #454545,
  "grey-dark": #4F4F4F,
  "grey-medium": #828282,
  "grey-light": #BDBDBD,
  "grey-lighter": #E0E0E0,
  "grey-lightest": #F2F2F2,
  "white": #FFFFFF,
  "red": #FF2A23
);

/*============*/
/* ANIMATIONS */
/*============*/
$duration: .3;

/*===============*/
/* MISCELLANEOUS */
/*===============*/
$shadow: 0 0 rem(24) rgba(0, 0, 0, 0.08);
$overlay-bg: transparentize(#09387C, 0.2);
