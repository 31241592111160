.section {
  padding: rem(72) 0;
  position: relative;
  background-color: color('white');

  @include mq(tablet) {
    padding: rem(120) 0;
  }

  &:not([class*="section--"]) + .section:not([class*="section--"]) {
    padding-top: 0;
  }
}

.section--retargeting {

  @include mq(tablet) {
    padding-bottom: rem(50);
  }
}

.section--support {

  @include mq(tablet) {
    padding-top: rem(60);
    padding-bottom: rem(40);
  }

  + .section {
    padding-top: 0;
  }
}

.section--top-small {

  @include mq(tablet) {
    padding-top: rem(48);
  }
}

.section--y-small {

  @include mq(tablet) {
    padding-top: rem(48);
    padding-bottom: rem(48);
  }
}

.section--primary,
.section--secondary {
  padding: rem(48) 0;

  @include mq(desktop) {
    padding: rem(64) 0;
  }
}

.section--primary {
  background-color: color('primary');
  background-image: gradient('primary');
  color: color('white');
}

.section--secondary {
  background-color: color('secondary');
  background-image: gradient('secondary');
  color: color('white');
}

.section--gray {
  background-color: #F2F2F2;
}

.section--border {

  @include mq(desktop) {
    padding-top: 0;
  }
}

.section__polygon {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  align-items: flex-end;

  img {
    width: 100%;
    height: auto;

    @include mq(desktop) {
      width: auto;
      height: 100%;
    }
  }
}

.section__polygon--top {
  top: 0;
  bottom: auto;
  align-items: flex-start;
}

.section__polygon--bottom {
  top: auto;
  bottom: 0;
  align-items: flex-end;
}

.section__polygon--right,
.section__polygon--left {
  width: 100%;
  height: auto;

  @include mq(tablet) {
    width: 50%;
    height: 100%;
  }
}

.section__polygon--right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  justify-content: flex-end;
}

.section__polygon--left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

.section--pt-small {
  padding-top: rem(90);
}
