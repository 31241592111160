.accordion {

}

.accordion__title {
  margin-bottom: rem(24);
  font-weight: 500;
  text-align: center;
  color: color('primary');
}

.accordion__item {
  padding: rem(16) 0;
  border-top: solid 1px color('grey-light');

  &:last-child {
    border-bottom: solid 1px color('grey-light');
  }
}

.accordion__item__summary {
  position: relative;
  padding-right: rem(32);
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: color('primary');
  }

  &::before {
    width: 1px;
    height: rem(25);
    top: calc(50% - #{rem(12)});
    right: rem(12);
    transition: transform duration();
  }

  &::after {
    width: rem(25);
    height: 1px;
    top: 50%;
    right: 0;
  }

  .accordion__item.expanded &::before {
    transform: scale(1, 0);
  }

  & > p {
    padding-left: rem(32);
    position: relative;
    line-height: rem(24);
    transition: color duration();

    &::before {
      width: rem(8);
      height: rem(8);
      content: '';
      position: absolute;
      top: calc(50% - #{rem(4)});
      left: 0;
      background-color: color('primary');
      border-radius: 50%;
    }
  }

  &:hover > p {
    color: color('primary');
  }

  .accordion__item.expanded & > p {
    font-weight: 500;
    color: color('primary');
  }
}

.accordion__item__details {
  height: 0;
  position: relative;
  overflow: hidden;
}

.accordion__item__content {
  padding: rem(16) rem(32) 0;

  ul {
    list-style-type: disc;
    color: color('grey');
    padding-left: rem(15);
  }
}
