.visual {
  width: rem(440);
  max-width: 100%;
  margin-top: rem(48);
  opacity: 0;

  @include mq(tablet) {
    margin-top: rem(56);
  }

  @include mq(desktop) {
    margin: 0 auto;
  }

  @include aspect-ratio(480, 320);
}

.visual--compensated {
  margin-right: auto;
  margin-left: auto;

  @include mq(desktop) {
    margin-top: 20%;
  }

  @include aspect-ratio(500, 400);
}

.visual__canvas {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
}

.visual__overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}


.visual--strategia-desktop {
  display: none;
  width: rem(945);
  height: rem(1350);
  cursor: pointer;

  @include mq(tablet) {
    display: block;
  }
}

.visual--strategia-mobile {
  width: rem(380);
  height: rem(1600);

  @include mq(tablet) {
    display: none;
  }
}
