
.contact-form__form {
  .row.row--inputs {
    column-gap: 24px;
    row-gap: 0;
  }
}

.contact-form__field-wrapper {
  .contact-form__label {
    display: block;
    font-family: 'SkyText';
    font-size: rem(16);
    color: color('grey');
    margin: rem(12) rem(21);
  }

  .contact-form__input {
    font-family: 'SkyText';
    width: 100%;
    border-radius: rem(20);
    line-height: rem(40);
    padding: 0 rem(20);
    font-size: rem(16);
    color: color('grey');
    border: rem(1) solid color('grey-light');
    outline-color: color('primary');
    outline: none;

    &::placeholder {
      color: #828282;
    }

    &:focus {
      border-color: color('primary') !important;
      box-shadow: inset 0 0 0 1px color('primary') !important;
    }
  }

  &.has-value {
    .contact-form__input {
      border-color: color('grey');
    }
  }
  &.has-error {
    .contact-form__input {
      border-color: color('red');
    }
  }
}

.contact-form__checkbox {
  position: relative;
  display: block;
  color: color('grey');
  font-size: rem(14);
  line-height: rem(24);
  padding-left: rem(36);
  cursor: pointer;

  a {
    color: color('primary');
    text-decoration: underline;
  }

  input {
    display: none;

    &:checked {

      ~ .contact-form__check {

        &:after {
          opacity: 1;
          transition: all .2s ease-in-out;
        }
      }
    }
  }

}

.contact-form__check {
  position: absolute;
  top: 0;
  left: 0;

  &:after,
  &:before {
    content:'';
    display: block;
  }

  &:before {
    width: rem(24);
    height: rem(24);
    border: rem(1) solid color('grey-light');
    border-radius: 50%;
  }

  &:after {
    position: absolute;
    top: rem(4);
    left: rem(4);
    width: rem(16);
    height: rem(16);
    border-radius: 50%;
    background-color: color('primary');
    opacity: 0;
    transition: all .2s ease-in-out;
  }
}

.contact-form__submit {
  font-family: 'SkyText';

  &.is-disabled {
    background: color('grey-medium');
    border-color: color('grey-medium');
    pointer-events: none;
  }
}

.contact-form__error {
  color: color('red');
  font-size: rem(16);
  padding-left: rem(21);
  padding-top: rem(5);
  display: none;

  .contact-form__checkbox & {
    padding: 0;
  }
}

.contact-form__success {
  padding-top: rem(302);
  padding-bottom: rem(302);
  display: none;
}

.contact-form__spinner {
  width: rem(64);
  height: auto;
  opacity: 0;
}
