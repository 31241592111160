.logo-box {
  width: rem(324);
  height: rem(144);
  padding: rem(16) rem(60);
  background-color: color('white');
  border-radius: rem($radius);
  box-shadow: $shadow;

  figure {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
