.menu {

}

.menu__hamburger {
  width: rem(32);
  height: rem(16);
  position: relative;
  z-index: 2;
  cursor: pointer;

  @include mq(desktop) {
    display: none;
  }

  & > span {
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    background-color: currentColor;
    transition-property: top, transform;
    transition-duration: duration();
    transition-delay: duration(), 0s;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 100%;
    }
  }

  .menu.open & > span {
    transition-delay: 0s, duration();

    &:nth-child(1) {
      top: 50%;
      transform: rotate(-45deg);
    }

    &:nth-child(2) {
      transform: scale(0, 1);
    }

    &:nth-child(3) {
      top: 50%;
      transform: rotate(45deg);
    }
  }
}

.menu__panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mq($until: desktop) {
    width: 100%;
    height: 100%;
    padding-top: rem($header-size-tablet);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: color('primary');
    background-image: gradient('primary');
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: .4s;

    .menu.open & {
      opacity: 1;
      visibility: visible;
    }
  }

  @include mq($until: tablet) {
    padding-top: rem($header-size-mobile);
    justify-content: space-between;
  }

  .nav {
    @include mq($until: desktop) {
      display: flex;
      width: 100%;
      padding: rem(48) rem(20);
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .nav__item {
    -webkit-tap-highlight-color: transparent;
    font-weight: 700;

    @include mq($until: desktop) {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include mq($from: tablet, $until: desktop) {
      font-size: rem(20);
      line-height: rem(32);
    }

    &:nth-child(n+2) {
      @include mq($until: desktop) {
        margin-top: rem(20);
        margin-left: 0;
      }

      @include mq($until: tablet) {
        margin-top: rem(16);
      }
    }
  }

  .nav__dropdown {
    position: relative;
    color: color('primary');

    .nav__item {
      font-weight: 400;
    }

    @include mq($until: desktop) {
      width: 100vw;
      height: 0;
      flex-shrink: 0;
      overflow: hidden;
      left: rem(-20);

      & > div {
        padding: rem(4) 0;
        margin-top: rem(12);
        background-color: color('white');

        .nav__item {
          width: calc(100% - #{rem(40)});
          padding-left: rem(24);
          margin: 0 auto;
          line-height: rem(32);

          &:nth-child(n+2) {
            border-top: solid 1px color('grey-lighter');
          }
        }

        .link::after {
          content: none;
        }
      }
    }

    @include mq(desktop) {
      width: 100%;
      height: rem($header-size-desktop);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      background-color: color('white');
      opacity: 0;
      visibility: hidden;
      transition-property: opacity, visibility;
      transition-duration: duration();
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        width: 100vw;
        height: rem($header-size-desktop);
        content: '';
        position: fixed;
        top: rem($header-size-desktop);
        left: 0;
        z-index: 0;
        background-color: color('white');
      }

      &::after {
        width: 100vw;
        height: calc(100vh - #{rem($header-size-desktop)});
        content: '';
        position: fixed;
        top: rem($header-size-desktop);
        left: 0;
        z-index: -1;
        background-color: $overlay-bg;
        pointer-events: none;
      }

      & > div {
        display: flex;
        width: auto;
        height: 100%;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
    }
  }

  .nav__item:hover > .nav__dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .nav__item > .icon {
    transition: transform duration();
  }

  .nav__item.open > .icon {
    transform: scale(1, -1);
  }
}

.menu__actions {
  display: flex;
  width: 100%;
  padding: rem(40) rem(20);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq(tablet) {
    width: auto;
    flex-direction: row;
  }

  @include mq(desktop) {
    padding: 0;
  }

  & > .button {
    &:first-child {
      @include mq($until: desktop) {
        border-color: currentColor;
      }

      @include mq(desktop) {
        min-width: 0;
      }
    }

    &:nth-child(n+2) {
      margin-top: rem(24);

      @include mq(tablet) {
        margin-top: 0;
        margin-left: rem(24);
      }

      @include mq(desktop) {
        margin-left: 0;
      }

      @include mq(wide) {
        margin-left: rem(24);
      }
    }
  }
}
