.costs {
  section:nth-of-type(2) {
    padding-top: rem(72);

    @include mq(tablet) {
      padding-top: rem(120);
    }

    @include mq(desktop) {
      padding-top: 0;
    }
  }
}
