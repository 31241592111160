.numbers {

  @include mq(tablet) {
    display: inline-flex;
  }
}

.numbers__item {
  min-width: rem(232);
  padding: rem(32) rem(12);
  position: relative;
  text-align: center;

  @include mq(tablet) {
    padding: 0 rem(12);
  }

  &:nth-child(n+2)::before {
    width: rem(128);
    height: 1px;
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - #{rem(64)});
    background-color: currentColor;

    @include mq(tablet) {
      width: 1px;
      height: 100%;
      left: 0;
      border-top: none;
      border-left: solid 1px currentColor;
    }
  }

  & > p {
    line-height: rem(24);
    font-weight: 300;

    strong {
      font-weight: 600;
    }
  }
}

.number {
  display: flex;
  margin-bottom: rem(8);
  justify-content: center;
  align-items: flex-end;
  font-size: rem(54);
  line-height: rem(48);
  font-weight: 500;

  & > span {
    font-size: rem(24);
    font-weight: 300;
    line-height: 1.2;
  }
}
