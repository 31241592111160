.table-block__title {
  padding-bottom: rem(48);

  @include mq(desktop) {
    padding-right: rem(24);
    padding-bottom: 0;
  }
}

.partner__logos {

  .table-block & {

    @include mq(desktop) {
      padding-left: rem(24);
    }
  }
}

.table-block__tableContainer {
  overflow-x: auto;
}

.table-block__table {
  width: 100%;
  min-width: rem(830);
  margin-top: rem(72);

  th {
    height: rem(80);
    font-size: rem(16);
    color: color('primary');
    text-transform: uppercase;
    border-bottom: rem(1) solid color('grey-lighter');

    @include mq(desktop) {
      height: rem(96);
    }

    &:not(:first-child) {
      border-top: rem(1) solid color('grey-lighter');
    }
  }

  .th-2 {
    text-align: left;
    padding-left: rem(40);
    border-top: 0 !important;
    position: relative;

    @include mq(desktop) {
      padding-left: rem(46);
    }

    &:before {
      content:'';
      display: block;
      width: calc(100% - #{rem(40)});
      height: rem(1);
      position: absolute;
      top: -1px;
      left: rem(40);
      background-color: color('grey-lighter');

      @include mq(desktop) {
        width: calc(100% - #{rem(46)});
        left: rem(46);
      }
    }
  }

  .th-3,
  .th-4 {
    width: rem(174);
  }

  tr:not(.table-spaziator) {
    td {
      border-right: rem(1) solid color('grey-lighter');
      padding-left: rem(40);
      padding-right: rem(40);

      @include mq(desktop) {
        padding-left: rem(46);
        padding-right: rem(46);
      }

      &.table-cell-title {
        padding-left: 0;
      }
    }
  }
}

.table-cell-title {
  position: relative;

  &:before {
    content:'';
    display: block;
    width: calc(100% - #{rem(40)});
    height: rem(1);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: color('grey-lighter');

    @include mq(desktop) {
      width: calc(100% - #{rem(46)});
    }
  }
}

.table-p {
  font-size: rem(17.6);
  line-height: rem(24);
  color: color('grey');
  padding-top: rem(24);
  padding-bottom: rem(24);
  border-bottom: rem(1) solid color('grey-lighter');

  &.table-p--no-padding {
    padding-top: 0;
  }
}

.table-cell-icon {
  text-align: center;
  position: relative;

  &:before {
    content:'';
    display: block;
    width: calc(100% - #{rem(80)});
    height: rem(1);
    position: absolute;
    bottom: 0;
    left: rem(40);
    background-color: color('grey-lighter');

    @include mq(desktop) {
      width: calc(100% - #{rem(96)});
      left: rem(46);
    }
  }
}

.table-spaziator {
  height: rem(24);
}

.table-align-items-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq(desktop) {
    flex-direction: row;
  }
}

.table-icon {
  width: rem(64);
  height: rem(64);
  border-radius: 50%;
  background-color: color('primary');
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq(desktop) {
    width: rem(80);
    height: rem(80);
  }
}

.table-label {
  padding-top: rem(8);
  font-size: rem(18);
  line-height: rem(32);
  color: color('primary');

  @include mq(desktop) {
    padding-top: 0;
    padding-left: rem(24);
    font-size: rem(22);
  }
}
