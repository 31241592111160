.header {
  width: 100%;
  height: rem($header-size-mobile);
  position: fixed;
  top: 0;
  z-index: 7;
  background-color: color('primary');
  background-image: gradient('primary');
  color: color('white');

  @include mq(tablet) {
    height: rem($header-size-tablet);
  }

  @include mq(desktop) {
    height: rem($header-size-desktop);
  }

  .container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.header__signature {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  @include mq(desktop) {
    width: rem(210);
  }

  @include mq(wide) {
    width: auto;
  }

  .icon {
    flex-shrink: 0;
    margin-right: rem(10);
    font-size: rem(40);

    @include mq(tablet) {
      margin-right: rem(14);
      font-size: rem(52);
    }

    @include mq(desktop) {
      font-size: rem(64);
    }
  }
}

.header__menu {
  @include mq(desktop) {
    height: 100%;
    flex-grow: 1;
    padding-left: 0;
  }

  @include mq(wide) {
    flex-grow: 1;
    padding-left: rem(116);
  }

  .menu__panel, .nav, .nav__item {
    @include mq(desktop) {
      height: 100%;
    }
  }
}
