.cards-grid {
  position: relative;
}

.cards-grid__background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: color('primary');
  background-image: gradient('primary');

  & > picture {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @include mq(desktop) {
      height: 100%;
    }

    img {
      width: auto;
      height: auto;

      @include mq(desktop) {
        height: 100%;
      }
    }
  }
}

.cards-grid__content {
  @include mq(desktop) {
    display: flex;
    justify-content: space-between;
  }
}

.cards-grid__item {
  max-width: rem(546);
  padding-bottom: rem(60);
  margin: 0 auto;
  text-align: center;
  color: color('white');

  @include mq(desktop) {
    max-width: rem(324);
    padding-top: rem(40);
    padding-bottom: rem(40);
    flex: 1 0 0;
    margin: 0;
  }

  &:nth-child(n+2) {
    @include mq(desktop) {
      margin-left: rem(24);
    }
  }

  & > .p-big {
    font-size: rem(22);
  }

  & > figure {
    display: flex;
    width: 100%;
    height: rem(264);
    overflow: hidden;
    justify-content: center;

    @include mq(desktop) {
      height: rem(208);
    }
  }

  &--small {
    > figure {
      height: rem(112);
    }
  }

  &--blue {
    color: color('primary');
  }
}
