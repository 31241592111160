@function size($size, $adjustment: 0) {
  @return map-get($breakpoints, $size) + ($adjustment * 1px);
}

@function color($color) {
  @return map-get($colors, $color);
}

@function gradient($type) {
  @if $type == 'primary' {
    @return linear-gradient(90deg, #0078E8 0.33%, #003896 84.90%);
  } @else if $type == 'secondary' {
    @return linear-gradient(90deg, #3562CF 0%, #844ED9 78.72%);
  }
}

@function duration($multiplier: 1) {
  @return #{$duration * $multiplier}s;
}

@mixin off-container {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
}
