.footer {
  height: 100%;
  padding: rem(16) 0 rem(56);
  overflow: visible;
  position: relative;
  z-index: 4;
  margin-top: rem(72);
  align-items: center;
  justify-content: space-between;
  background-color: color('white');
  color: color('grey');

  @include mq(tablet) {
    display: flex;
    flex-wrap: wrap;
    padding: rem(32) 0 rem(40);
    margin-top: rem(120);
  }

  @include mq(desktop) {
    padding: rem(28) 0;
    margin-top: rem(200);
  }

  @include mq($until: desktop) {
    display: flex;
    flex-direction: column;
    align-content: center;
    & > * {
      text-align: center;
    }
  }

  &::before {
    width: 100vw;
    height: calc(100% + #{rem(72)});
    content: '';
    position: absolute;
    bottom: 0;
    left: calc((100vw - 100%) / -2);
    z-index: -1;
    background-color: color('white');
    background: linear-gradient(rgba(255, 255, 255, 0), #{color('white')} 25%);

    .partner & {
      background: color('white');
    }

    @include mq(tablet) {
      height: calc(100% + #{rem(120)});
    }

    @include mq(desktop) {
      height: calc(100% + #{rem(200)});
    }
  }

  &::after {
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: color('grey-lighter');
  }
}

.footer__signature {
  margin-bottom: rem(30);

  @include mq(desktop) {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  img {
    height: rem(32);
    flex-shrink: 0;
    margin-bottom: rem(16);

    @include mq(desktop) {
      height: rem(40);
      margin-bottom: 0;
      margin-right: rem(52);
    }
  }

  small {
    display: block;
  }
}

.footer__logo {
  display: block;
  height: rem(56);
}

.footer__menu {
  width: 100%;
  margin-top: rem(56);

  @include mq(tablet) {
    margin-top: rem(40);
    text-align: center;
  }

  @include mq(desktop) {
    width: auto;
    margin-top: 0;
    text-align: right;
  }

  .nav__item:nth-child(n+2) {
    @include mq(tablet) {
      margin-left: rem(48);
    }
  }

  @include mq($until: desktop) {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-right: 0;
    & > * {
      justify-content: center;
    }
  }

}

.footer__extra {
  display: none;
}
