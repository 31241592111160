.addressable-tv {

  @include mq($until: desktop) {
    .section--secondary {
      .col {
        text-align: center;
        br {
          display: none;
        }
      }
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .mobile-divisor {
      position: relative;
      width: 100% !important;
      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);
        height: rem(2);
        background-color: #255CB9;
      }
    }
    .channels__container.mobile-divisor::after {
      width: 100%;
      bottom: rem(-72);
    }
  }

  @include mq(desktop) {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

}

.channels {
  padding-top: 0;
  @include mq(desktop) {
    color: color('white');
  }
}

.channels__container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  color: color('black');

  &:nth-child(1) {
    margin-bottom: 7rem;
  }

  .desktop & {
    width: 66%;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    & > * {
      display: grid;
      place-content: center;
      height: rem(157);
    }

    & + .channels__container ~ .channels__container {
      margin-top: 5rem;

      h3 {
        position: relative;
        width: 17rem;

        &::before {
          position: absolute;
          content: '';
          width: 1px;
          height: rem(104);
          left: 50%;
          transform: translateX(-50%);
          background-color: #E0E0E0;
          top: rem(-90);
        }
      }
    }

  }

  .mobile & {
    h3 {
      margin-top: rem(80);
      margin-bottom: rem(30);
      position: relative;
      &::after {
        position: absolute;
        content: '';
        width: rem(340);
        height: rem(1);
        background-color: rgba($color: #BDBDBD, $alpha: 0.3);
        top: -2.2rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.channels__item {
  width: clamp(260px,25vw,160px);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;

  &--slider {
    display: block !important;
  }

  img {
    width: auto;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
  }

  small {
    // all: unset;
    @extend .mt-2;
    @extend .p-small;
    @extend .c-grey;
  }

  small.c-white {
    @extend .c-white;
    font-size: 1.5rem;
  }

  @include mq(desktop) {
    &:nth-child(2)::before {
      position: absolute;
      content: '';
      width: rem(1);
      height: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba($color: #fff, $alpha: 0.3);
    }
  }
}

.section__svg {
  display: none;

  @include mq(desktop) {
    background-color: color('primary');
    background-image: gradient('primary');
    height: rem(360);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;

    svg {
      opacity: 0.2;
    }
  }

  .section--retargeting & {
    background-color: color('primary');
    background-image: gradient('primary');
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;

    @include mq(desktop) {
      height: rem(360);
    }

    svg {
      opacity: 0.2;
    }
  }
}

.section--advanced-campaigns-header {
  display: flex;

  @include mq(desktop) {
    padding: rem(40) 0;
  }
}

.section--advanced-campaigns-numbers {
  padding-top: 0;
  @include mq(desktop) {
    color: color('white');
  }

  &:after,
  &:before {
    content:'';
    display: block;
    width: calc(100% - #{rem(64)});
    height: rem(2);
    position: absolute;
    left: rem(32);
    background-color: color('primary');

    @include mq(desktop) {
      display: none;
    }
  }

  &:after {
    top: 0;
  }

  &:before {
    bottom: 0;
  }
}

.section--retargeting {
  padding-top: 0;
  color: color('white');

  &:after,
  &:before {
    content:'';
    display: block;
    width: calc(100% - #{rem(64)});
    height: rem(2);
    position: absolute;
    left: rem(32);
    background-color: color('primary');

    @include mq(desktop) {
      display: none;
    }
  }

  &:after {
    top: 0;
  }

  &:before {
    bottom: 0;
  }
}

.websites__container,
.websites__numbers__container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;

  @include mq(desktop) {
    flex-direction: row;
    justify-content: center;
    height: rem(150);
  }
}

.websites__container {
  color: color('black');

  @include mq(desktop) {
    margin-bottom: 9rem;
  }

  &.align-center {
    justify-content: center;
  }
}

.websites__logo {
  width: clamp(260px,25vw,160px);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding-bottom: rem(72);
  margin-bottom: rem(48);
  border-bottom: rem(1) solid rgba(color('grey-light'), .3);

  @include mq($until: desktop) {
    width: clamp(300px,25vw,160px);

    &:last-child {
      padding-bottom: rem(48);
      margin-bottom: 0;
    }
  }

  &--md {
    width: clamp(260px,100vw,590px);
  }

  @include mq(desktop) {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }

  img {
    width: auto;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
  }
  small {
    line-height: rem(24);
    color: color('black');
    font-size: 1.5rem;
    margin-top: 2.7rem;
  }
  @include mq(desktop) {
    small {
      color: color('white');
    }
    &:nth-child(2)::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba($color: #fff, $alpha: 0.3);
    }
  }
}

.websites__numbers__container {

  @include mq(desktop) {
    margin-top: 2rem;
  }

  .websites__numbers {
    margin-top: 2rem;
    width: clamp(250px,25vw,160px);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    min-height: rem(230) !important;
    border-bottom: rem(1) solid rgba(color('grey-light'), .3);

    @include mq(desktop) {
      border-bottom: 0;
      padding-right: rem(32);
      padding-left: rem(32);
    }

    &:last-child {
      border-bottom: 0;
    }

    img {
      width: auto;
      height: rem(60);
      object-fit: contain;
      margin: 0 auto;
    }

    small {
      font-size: rem(17.6);
      line-height: rem(24);
      color: color('grey');
      width: 14ch;
      min-height: rem(72);
      margin: 0 auto 2rem;
    }

    .websites__number {
      font-size: 1.5rem;
      line-height: 3rem;
      margin-top: 1.5rem;
      color: color('black');

      strong {
        font-size: 1.8em;
        font-weight: 500;
      }
    }

  }
}

@include mq(desktop) {
  .websites__container--tre {
    .websites__numbers {
      position: relative;
      &:nth-of-type(2)::before,
      &:nth-of-type(2)::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        top: 0;
        background-color: rgba($color: #E0E0E0, $alpha: 1.0);
      }
      &:nth-of-type(2)::before {
        left: 0;
      }
      &:nth-of-type(2)::after {
        right: 0;
      }
    }
  }

  .websites__container--due {
    .websites__numbers {
      &:nth-of-type(2)::before {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba($color: #E0E0E0, $alpha: 1.0);
      }
    }
  }

}

.slideshow-solution {
  padding: 0 rem(10);
  text-align: center;
}

.addressable-tv-header {

  @include mq(desktop) {
    padding: rem(40) 0;
  }
}
