.share-buttons {
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: rem(50);
  display: flex;
  justify-content: flex-end;
  color: #7C7C7C;

  li {
    margin-left: rem(24);
    line-height: rem(40);
  }
}

.share-buttons__icon {

  a {
    width: rem(40);
    height: rem(40);
    display: flex;
    justify-content: center;
    align-items: center;
    border: rem(1) solid #255CB9;
    border-radius: 50%;
  }
}
