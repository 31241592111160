.tabview {
  &__tabs__row {
    gap: 20px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0 calc(50vw - 8.75rem);

    @include mq(tablet) {
      gap: 38px;
      margin-left: 16px;
      margin-right: 16px;
      padding: 0 calc(50vw - 20.375rem);
      
    }
    @include mq(desktop) {
      gap: 26px;
      margin-left: 40px;
      margin-right: 40px;
      padding: 0;
    }
  }
  &__tabs__col{
    height: 100%;
  }
  .tabview__button {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border-width: 2px;
    border-color: color('primary');
    color: color('primary');
    font-size: 24px;
    line-height: 1;
    font-weight: 500;

    &.active {
      background-color: transparent;
      background-image: gradient('secondary');
      border: 0 none;
      color: #fff;
    }
  }
}

.tabview__tabs {
  overflow: hidden;
  position: relative;
  margin: 0 auto rem(80);

  .swiper-container {
    padding: 0 calc((100vw - #{rem(328)}) / 2 + #{rem(24)});
  }

  &--margin {
    margin-bottom: 20px;
  }

  @include mq(tablet) {
    .swiper-container {
      padding: 0 calc((100vw - #{rem(700)}) / 2 + #{rem(24)});
    }
  }

  @include mq(desktop) {
    max-width: rem(1184);

    .swiper-container {
      padding: 0 rem(24);
    }
  }
}

.tabview__tab {
  display: flex;
  width: rem(130);
  height: rem(120);
  padding: rem(16);
  flex-shrink: 0;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border: solid 1px color('grey-light');
  text-align: center;
  color: color('grey');
  transition-property: border-radius, color;
  transition-duration: duration();

  &::before {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 0;
    background-color: color('secondary');
    background-image: gradient('secondary');
    opacity: 0;
    transition-property: border-radius, opacity;
    transition-duration: duration();
  }

  &:hover,
  &.active {
    border-radius: rem($radius / 2);
    color: color('white');

    &::before {
      border-radius: rem($radius / 2);
      opacity: 1;
    }
  }

  &:not(.tabview__button) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: color('white');
      transition-property: border-radius, background-color, opacity;
      transition-duration: duration();
    }

    &:hover:not(.active) {
      border-radius: 0 !important;
      background-color: gradient('secondary');
      color: inherit;
  
      &::before {
        border-radius: 0 !important;
      }
  
      &::after {
        background-color: color('grey-lightest');
      }
    }
  }

  &.active {
    color: color('white');

    &::after {
      opacity: 0;
    }
  }

  &:not(.active) {
    cursor: pointer;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

.tabview__arrow {
  width: calc((100vw - #{rem(328)}) / 2 + #{rem(24)});
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: color('white');
  cursor: pointer;

  &::before {
    width: rem(14);
    height: rem(14);
    content: '';
    position: absolute;
    top: 50%;
    transition: opacity duration();
  }

  &.swiper-button-disabled {
    cursor: default;

    &::before {
      opacity: 0.25;
    }
  }

  @include mq(tablet) {
    width: calc((100vw - #{rem(700)}) / 2 + #{rem(24)});
  }

  @include mq(desktop) {
    width: rem(40);
  }
}

.tabview__arrow--prev {
  left: 0;

  &::before {
    right: rem(12);
    transform: translateY(-50%) rotate(-45deg);
    border-top: solid 2px currentColor;
    border-left: solid 2px currentColor;
  }
}

.tabview__arrow--next {
  right: 0;

  &::before {
    left: rem(12);
    transform: translateY(-50%) rotate(45deg);
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
  }
}

.tabview__panes {
  position: relative;
}

.tabview__pane {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity duration();

  &--margin {
    margin-top: 70px;
  }

  &.active {
    position: relative;
    z-index: 1;
    opacity: 1;
    pointer-events: all;
  }
}
