.steps-payoff {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 35px;
  color: #255CB9;
  padding: 11px;
  p {
    font-size: 20px;
  }
}
#scroll-anchor {
  // display: none;
  scroll-margin: 100px;
}

#previous-steps-resume {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  .box {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    width: 25%;
    position: relative;
    .text-wrapper {
      position: absolute;
      max-width: 100%;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .middle {
        background-color: #fff;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 8px;
      }
      span {
        background: -webkit-linear-gradient(#0085FF, #0044B6);
        -webkit-background-clip: text;
        max-width: 75%;
        -webkit-text-fill-color: transparent;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
      }
    }
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
}

@media (max-width:450px) {
  .title-container {
    text-align: center;
  }
  #previous-steps-resume {
    .box {
      width: 50%;
      .text-wrapper {
        span {
          font-size: 14px !important;
          line-height: 15px;
        }
      }
    }
  }
}

.steps-section {
  padding-top: 10px;
  padding-bottom: 3.5rem;
}

#steps-wrapper {
  padding-top: 0px !important;
  .steps {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    .strategy-title {
      font-size: 24px;
      color: blue;
      font-weight: 400;
      text-align: center;
      position: absolute;
      left: -200px;
      width: 120px;
      top: 50%;
      transform: translateY(-50%);
      span {
        background: -webkit-linear-gradient(#0085FF, #0044B6);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .step {
      padding: 32px;
      // transition: 0.5s ease all;
      max-width: 900px;
      min-height: 20px;
      max-height: 160px;
      border-radius: 24px;
      justify-content: inherit;
      align-items: inherit;
      margin-bottom: 32px;
      position: relative;
      .hero__background {
        opacity: 0;
        // transition: .7s ease all;
        max-height: 360px;
        bottom: 0px !important;
        top: inherit;
        right: -70px;
        left: inherit;
      }
      &.extended-step {
        max-height: max-content;
        opacity: 1 !important;
        h4 {
          margin-top: 20px;
          margin-bottom: 15px;
        }
        ul {
          margin-left: 30px;
          list-style-type: disc;
        }
        .hero__background {
          opacity: 1 !important;
        }
      }
      .step-body {
        z-index: 1;
        width: 100%;
        .step-body-title {
          display: flex;
          .left-title {
            display: flex;
            padding-right: 15px;
            flex: 1;
            .icon {
              padding: 24px;
              width: 96px;
              height: 96px;
              border-radius: 100%;
              background-color: white;
              box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
            }
            .title {
              display: flex;
              vertical-align: middle;
              align-items: center;
              margin-left: 25px;
              flex: 1;
              h3 {
                letter-spacing: 1px;
                font-size: 24px;
                font-weight: 400;
              }
            }
            .description {
              font-size: 18px;
              margin-top: rem(8);
              display: none;
            }
          }
          .right-title {
            text-align: center;
            align-items: center;
            vertical-align: middle;
            position: relative;
            display: flex;
            font-weight: 400;
            .close-button {
              font-size: 25px;
              display: none;
              position: absolute;
              top: 0;
              left: -20px;
            }
          }
        }
      }
      .step-footer {
        text-align: right;
        width: 100%;
      }
      .details {
        margin-top: 48px;
        .details-row {
          display: flex;
          border-bottom: 1px dashed white;
          &:last-child{
            border-bottom: 0;
          }
          .detail-column {
            padding: 10px 20px 10px 20px;
            border-right: 1px dashed white;
            margin-bottom: 10px;
            min-height: 92px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            vertical-align: middle;
            width: 100%;
            max-width: 25%;
            &:nth-child(4) {
              border-right: 0;
            }
            h4 {
              font-weight: bold;
              font-size: 20px;
            }
            .info {
              text-align: center;
              margin: 0 auto;
              .detail-description {
                font-size: 14px;
              }
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
        opacity: 1 !important;
      }
      &.active {
        max-height: 500px;
        .close-button {
          display: block !important;
        }
        .right-title {
          .expand {
            display: none !important;
          }
        }
        .hero__background {
          opacity: 0.5;
        }
        .description {
          display: block !important;
        }
        &.hero--primary {
          opacity: 1 !important;
        }
        &.short-step {
          .step-footer {
            display: block;
          }
        }
      }
      &.hero--primary {
        opacity: 0.8 ;
        transition: all .2s ease;

        &:not(.active){
          position: relative;
          background-color: color('white');
          background-image: none;
          color: color('primary');

          // GRADIENT BORDER
          &:before {
            content: "";
            position: absolute;
            inset: 0;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            border: 1px solid transparent;
            border-radius: 24px;
            background: gradient('primary');
            z-index: 0;
          }

          &:hover {
            background-color: color('grey-lightest');
          }

          .title h3,
          .right-title {
            font-weight: 500 !important;
            background: gradient('primary');
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
          }
        }
      }
      &.short-step {
        .step-footer {
          display: none;
        }
      }
      &.with-connector {
        overflow: visible;
        margin-bottom: 0px !important;
        .connector {
          color: transparent;
          position: absolute;
          left: -35px;
          top: 49%;
          img {
            height: 132px;
          }
        }
      }
    }
    .connector-mob {
      text-align: center;
      padding-top: 18px;
      padding-bottom: 18px;
      span {
        color: #255CB9;
        font-size: 55px;
      }
      /*
      img {
        height: 90px;
      }
      */
    }
  }
}

#fourth-step-wrapper {
  #steps-wrapper {
    .steps {
      .step {
        &.active {
          max-height: none;
        }
      }
    }
  }
}

#steps-navbar {
  background-color: white;
  padding: 15px;
  margin-bottom: 45px;
  .content {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    .content-wrapper {
      margin: 0 auto;
      display: flex;
    }
  }
  .arrow-button-mob {
    padding-top: 5px;
    &[disabled] {
      opacity: 0.1;
    }
  }
  .nav-steps {
    max-width: 700px;
    margin-left: 20px;
    margin-right: 20px;
    .mobile-step-title {
      text-align: center;
      color: #255CB9;
      font-weight: 500;
      margin-bottom: 10px;
      font-size: 18px;
      letter-spacing: 1px;
    }
    // border-bottom: 1px solid #787878;
    ul {
      li {
        float: left;

        &.active {
          // border-bottom: 1px solid #255CB9;
          .anim-border {
            .border-fill {
              width: 100%;
            }
          }
        }
        &.last-active {
          a {
            color: #255CB9;
            font-weight: bold;
          }
        }
        a {
          color: color('grey');
          padding: 10px 20px 10px 20px;
          display: block;
          letter-spacing: 1px;
        }
        .anim-border {
          height: 2px;
          background-color: #BDBDBD;
          width: 100%;
          display: block;
          .border-fill {
            transition: 0.5s ease all;
            height: 2px;
            width: 0%;
            display: block;
            background-color: #255CB9;
          }
        }
      }
    }
  }
  &.is-fixed {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
}

.title-container {
  margin-top: 45px;
  max-width: 900px;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 45px;
  transition: 0.5s ease all;
  opacity: 0;
  height: 0px;
  .slide-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    background: -webkit-linear-gradient(#0085ff,#0044b6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.hide {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
  &.show {
    opacity: 1;
    height: auto;
  }
  .slide-description {
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #255CB9;
  }
}

@media only screen and (max-width: 450px) {
  .title-container {
    .slide-title {
      font-size: 22px;
    }
    .slide-description {
      font-size: 18px;
    }
  }
}

@media (min-width:1025px) {
  .show-on-tablet {
    display: none !important;
  }
}
@media (min-width:1281px) {
  .show-on-tablet {
    display: none !important;
  }
}

@media only screen and (min-width: 670px) {
  .hide-on-mob {
    display: block;
  }
  .show-on-mob {
    display: none;
  }
}

@media only screen and (max-width: 670px) {
  .hide-on-mob {
    display: none !important;
  }
  .show-on-mob {
    display: block;
  }
  .hide-on-mob {
    display: none !important;
  }

  .steps-payoff {
    text-align: center;
  }
  .hero__background {
    right: 0px !important;
  }
  .step {
    margin: 10px;
    &.active {
      max-height: fit-content !important;
    }
    .description {
      margin-top: 15px;
    }
    &.with-connector {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    &.first-step {
      max-height: 96px !important;
      padding-top: 37px;
      &.active {
        max-height: fit-content !important;
        .icon {
          display: block !important;
          margin-bottom: 40px;
          text-align: center;
          display: flex !important;
          align-items: center;
          img {
            margin: 0 auto;
          }
        }
      }
    }
    .left-title {
      display: block !important;
      .title {
        margin-left: 0px !important;
        h3 {
          line-height: 24px;
          margin-bottom: 0;
          font-weight: 500 !important;
        }
      }
    }
    .first-step {
      .icon {
        display: none !important;
      }
      .title {
        margin-left: 0px !important;
        margin-top: 40px;
        h3 {
          line-height: 20px;
          margin-bottom: 0;
          font-weight: 500 !important;
          font-size: 24px !important;
        }
        .description {
          margin-top: 15px !important;
        }
      }
    }
  }
  .strategy-title {
    position: relative !important;
    left: auto !important;
    width: auto !important;
    top: auto !important;
    transform: none !important;
    margin-bottom: 20px !important;
  }
  .details-row {
    display: block !important;
    border-bottom: 0 !important;
    .detail-column {
      max-width: 100% !important;
      border-right: 0 !important;
      border-bottom: 1px dashed white;
      h4 {
        font-size: 22px !important;
        font-weight: 500 !important;
      }
    }
  }
  .step-footer {
    text-align: center !important;
    margin-top: 25px;
  }
  .icon {
    padding: 15px !important;
  }
  #scroll-anchor {
    display: block !important;
  }
}

@media (max-width: 1199px) {
  #steps-navbar {
    ul {
      li {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 1024px) {
  #steps-navbar {
    padding: 30px 15px 15px 15px !important;
    .nav-steps {
      .mobile-step-title {
      }
      ul {
        li {
          min-width: 35px;
          margin: 10px;
          a {
            display: none !important;
          }
          &.active {

          }
          &.last-active {
            a {

            }
          }
        }
      }
    }
  }
}

@media 
      (min-width: 671px)
  and (max-width: 1024px){
    .hide-on-tablet {
      display: none !important;
    }
    .show-on-tablet {
      display: block !important;
    }
}

@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
  .steps-payoff {
    text-align: center;
  }
  .hero__background {
    right: 0px !important;
  }
  .step {
    margin: 10px;
    &.active {
      max-height: fit-content !important;
    }
    .description {
      margin-top: 15px;
    }
    &.with-connector {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }
  .strategy-title {
    position: relative !important;
    left: auto !important;
    width: auto !important;
    top: auto !important;
    transform: none !important;
    margin-bottom: 20px !important;
  }
  .details-row {
    display: flex !important;
    .detail-column {
      max-width: 50% !important;
      border-bottom: none;
      &:nth-child(2) {
        border-right: 0 !important;
      }
      h4 {
        font-size: 22px !important;
      }
    }
  }
  .step-footer {
    margin-top: 25px;
  }
  #scroll-anchor {
    display: block !important;
  }
  #steps-navbar {
    padding: 30px 15px 15px 15px !important;
    .nav-steps {
      ul {
        li {
          min-width: 35px;
          margin: 10px;
          a {
            display: none !important;
          }
        }
      }
    }
  }
}

.steps-belt {
  width: 100%;
  overflow: hidden;
}

.slides-container {
  height: 840px;
  // transition: 800ms cubic-bezier(0.48, 0.15, 0.18, 1);
  position: relative;
}

.step-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.ac-second-step,
.ac-third-step,
.ac-fourth-step {

  @include mq(desktop-large) {
    padding-top: rem(55);
  }
}
