.step {
  & > .icon {
    margin-left: rem(52);
    font-size: rem(52);
  }
}

.step__content {
  display: flex;
  margin-top: rem(24);
  align-items: flex-start;
}

.step__index {
  width: rem(20);
  font-weight: 500;
  line-height: rem(24);
  text-align: center;
}

.step__text {
  padding: 0 rem(16);
  margin-left: rem(16);
  border-left: solid 1px currentColor;
}

.step__title {
  margin-bottom: rem(16);
  line-height: rem(24);
}
