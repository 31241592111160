.single-post {
  padding-bottom: rem(30);

  @include mq(desktop) {
    height: rem(900);
    padding-bottom: rem(120);
  }

  .c-white {

    @include mq(0px, desktop) {
      color: color('black');
    }
  }
}

.section__svg {

  .single-post & {
    top: auto;
    bottom: 0;
    justify-content: flex-start;
  }
}

.single-post__figure {
  position: relative;
  z-index: 1;
  text-align: center;
}

.single-post__image {
  width: auto;
  max-height: rem(567);
}

.single-post__content {
  padding-bottom: 0;

  a {
    color: color('primary')
  }

  p {
    color: color('grey');
  }

  ul, ol {
    color: color('grey');
    margin: rem(16) 0 rem(16) rem(20);
  }

  ul {

    li {
      list-style-type: disc;
    }
  }
}
