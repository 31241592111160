.filter-wrapper {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  .filter-links {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: block;
    width: fit-content;
    height:auto;
    overflow: hidden;
    background: white;
    li {
      display: inline;
      float: left;
      a {
        display: block;
        color: black;
        padding: 1em 2em;
        text-decoration: none;
        transition: color .5s ease-in-out;
        &.title {
          color: #929292;
        }
        &.is-selected {
          color: color('primary');
          border-bottom: 2px solid color('primary');
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .filter-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .filter-links {
    width: 950px !important;
  }
}


.filter-sections {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 600px;
  article {
    opacity: 0;
    display: none;
    &.view {
      display: block !important;
      opacity: 1 !important;
      // transform: scale(1) rotate(0deg);
      transform: scale(1) translateY(10px);
      animation: selected 300ms 1 ease-in-out;
    }
  }
}

@keyframes selected {
  0% {
    border-radius:100%;
    transform: scale(0)  translateY(0px);
  }
}
