.carousel-filters {
  border-bottom: rem(2) solid color('primary');
  position: relative;
  width: calc(100% - 40px);
  margin-left: rem(20);

  @include mq(desktop) {
    border-bottom: 0;
    display: flex;
    justify-content: center;
  }

  .swiper-wrapper {

    @include mq(desktop) {
      display: flex;
      justify-content: center;
    }
  }

  .swiper-slide {

    @include mq(desktop) {
      width: auto;
    }
  }
}

.carousel-filters__el {
  font-family: 'SkyText', sans-serif;
  font-weight: 500;
  width: 100%;
  border: 0;
  background: transparent;
  font-size: rem(18);
  line-height: rem(24);
  padding-bottom: rem(16);
  color: color('primary');
  cursor: pointer;

  @include mq(desktop) {
    width: rem(180);
    height: rem(43);
    color: color('grey');
    border-bottom: rem(.5) solid color('grey-light');
    font-weight: 400;
    transition: all .2s ease-in-out;

    &.is-active {
      font-weight: 500;
      color: color('primary');
      border-bottom: rem(2) solid color('primary');
      transition: all .2s ease-in-out;
    }
  }
}

.carousel-filters__arrow {
  position: absolute;
  top: 50%;
  width: rem(8);
  height: rem(8);
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 6;
  border-color: color('primary');

  @include mq(desktop) {
    display: none;
  }

  &.swiper-button-disabled {
    border-color: color('grey-light');
  }
}

.carousel-filters__arrow--prev {
  left: 0;
  border-left: rem(1) solid;
  border-top: rem(1) solid;
}

.carousel-filters__arrow--next {
  right: 0;
  border-right: rem(1) solid;
  border-bottom: rem(1) solid;
}

.carousel-container {
  margin-top: rem(24);
  position: relative;
  padding-left: rem(20);
  padding-right: rem(20);

  @include mq(desktop) {
    margin-top: rem(56);
    padding: 0;
  }
}

.carousel-inspirations {
  position: relative;
  overflow: hidden;
  padding-bottom: rem(32);

  @include mq(desktop) {
    padding-bottom: 0;
  }

  .swiper-slide {

    @include mq(desktop) {
      height: auto;
    }
  }

  .non-swiper-slide {
    display: none;
  }

  .swiper-pagination {
    bottom: 0;

    @include mq(desktop) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    border-radius: 0;
    height: rem(2);
    width: rem(32);
  }
}

.carousel-inspirations__card {
  background-color: color('white');
  padding: rem(16);
  border-radius: rem(24);
  position: relative;

  @include mq(desktop) {
    padding: rem(24) rem(24) rem(96) rem(24);
    border-radius: rem(32);
    height: 100%;
    box-shadow: 0 0 rem(24) rgba(0, 0, 0, 0.08);

    .button {
      position: absolute;
      bottom: rem(24);
      left: rem(24);
    }
  }
}

.carousel-inspirations__figure {
  border-radius: rem(8);
  overflow: hidden;
  margin-bottom: rem(16);

  @include mq(desktop) {
    margin-bottom: rem(24);
  }
}

.carousel-inspirations__title {
  font-size: rem(20);
}

.carousel-inspirations__arrow-container {
  position: absolute;
  left: rem(-70);
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @include mq(desktop) {
    display: block;
  }

  @include mq(desktop-large) {
    left: rem(-116);
  }
}

.carousel-inspirations__arrow {
  width: rem(64);
  height: rem(64);
  border-radius: 50%;
  background-color: color('primary');
  background-image: gradient('primary');
  position: relative;
  display: none;
  cursor: pointer;

  &.swiper-button-disabled {
    background: color('grey-medium');
  }

  @include mq(desktop) {
    display: block;
  }

  &:after {
    content:'';
    display: block;
    width: rem(12);
    height: rem(12);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.carousel-inspirations__arrow--prev {

  &:after {
    border-left: rem(2) solid color('white');
    border-top: rem(2) solid color('white');
  }
}

.carousel-inspirations__arrow--next {
  margin-bottom: rem(16);

  &:after {
    border-right: rem(2) solid color('white');
    border-bottom: rem(2) solid color('white');
  }
}
