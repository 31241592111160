.section__numberedList {
  padding-bottom: 4rem;
  ol {
    list-style: none;
  }
  .counter,
  .accordion {
    counter-set: a 0;
    li::before {
      counter-increment: a;
      content: counter(a) '. ';
    }
    ol {
      counter-set: b 0;
      li::before {
        counter-increment: b;
        content: counter(b) '. ';
      }
    }
  }
  .list {
    position: relative;
    margin-left: 1.6rem;
  }
  .accordion__item {
    border-top-color: color('grey-lighter');
  }
  .accordion__item__summary {
    color: color('grey');
    & > p::before {
      width: rem(8);
      height: rem(8);
      counter-increment: a;
      content: counter(a) '.';
      position: absolute;
      top: 0;
      left: rem(2);
      background-color: transparent;
      color: color('primary');
      font-weight: 500;
    }
  }
  .accordion__title {
    display: none;
  }
  .accordion__item__content {
    .list {
      margin-left: 0;
      li {
        margin-left: 1.5rem;
        position: relative;
        &:before {
            top: 0;
          left: -1rem;
          position: absolute;
        }
      }
    }
  }
}

.ul-disc {
  list-style-type: none;
  li {
    position: relative;
    padding-left: 0.9rem;
    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: color('grey');
    }
  }
}

.table-standard {
  th {
    text-transform: uppercase;
    font-weight: 500;
    height: 5rem;
    border-top: 1px solid color('grey-lighter');
    border-bottom: 1px solid color('grey-lighter');
    &:not(:last-child) {
      border-right: 1px solid color('grey-lighter');
    }
  }
  td {
    border-bottom: 1px solid color('grey-lighter');
    &:not(:last-child) {
      border-right: 1px solid color('grey-lighter');
    }
  }
  &.cookies {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 1.2rem .6rem;
      &:first-child() {
        width: 24%;
        border-right: 1px solid color('grey-lighter');
        padding-left: 0;
      }
      &:nth-child(2) {
        width: 38%;
        border-right: 1px solid color('grey-lighter');
      }
      &:last-child() {
        width: 36%;
      }
    }
    th {
      text-align: left;
    }
    td {
      vertical-align: top;
      p {
        max-width: 33ch;
        line-height: 1.5rem;
        text-align: left;
        color: color('grey');
        word-wrap: break-word;
        font-size: 1rem;
      }
    }
    @include mq($until: desktop) {
      thead {
        display: none;
      }
      tr {
        display: block;
        margin-bottom: 0.625em;
      }
      td {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 0;
        display: block;
        text-align: right;
        width: 100% !important;
        position: relative;
        padding-top: 2.5rem;
        &:first-child() {
            padding-left: .6rem;
        }
        p {
          max-width: 100%;
        }
        &:before {
          position: absolute;
          content: attr(data-label);
          top: 1rem;
          left: 0.5rem;
          font-weight: bold;
          text-transform: uppercase;
        }
        &:last-child {
          border-bottom: 1px solid #ddd;
        }
        &[scope='row'] {
          margin-top: 1.5rem;
        }
      }
    }
  }
}

.faq .accordion__item__summary {
  color: color('grey');
}

.perche-scegliere {
  .hero__content {
      text-align: left;
  }
  .p-big {
      font-size: rem(18);
  }
  .width-70 {
      width: 88%;
  }
  .padding-middle {
    padding-right: .5rem;
  }
  .md\:row--reverse {
    .padding-middle {
      padding-right: 0;
    }
  }
  .carousel__slide {
    figure {
      img {
        margin-top: 1rem;
      }
    }
  }
}


.dev {
    --c: #85c383;
    display: none;
    // display: flex;
    width: 100%;
    padding: .3rem;
    justify-content: space-evenly;
    font-size: 11px;
    background-color: var(--c);
    a:hover {
        color: #333;
    }
}