.homepage {
  .section:nth-of-type(4) {
    @include mq($until: desktop) {
      padding-bottom: 0;
    }
  }

  .section:nth-of-type(6) .col:last-child {
    @include mq($until: desktop) {
      grid-row: 1;
    }
  }
}
