.carousel {
  height: 100%;
  padding-bottom: rem(56);

  @include mq(tablet) {
    padding-bottom: rem(72);
  }

  @include mq(desktop) {
    padding-bottom: rem(160);
  }

  .swiper-wrapper {
    height: 100%;
  }
}

.carousel--large {
  padding-bottom: rem(48);
}

.carousel--small {
  padding: 0;
}

.carousel--boxed {
  padding: 0;
  overflow: hidden;
  border-radius: rem($radius);
  text-align: center;
  text-shadow: 0 0 2px rgba(0, 0, 0, .25);
  color: color('white');
}

.carousel__slide {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  align-items: center;

  & > * {
    position: relative;
  }

  & > figure {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    .carousel--large & {
      position: relative;
      border-radius: rem(24);
      overflow: hidden;
    }

    .carousel--small & {
      position: relative;
    }

    & > img {
      width: 100%;
      object-fit: cover;
    }
  }

  & > h3 {

    .carousel--large & {
      padding-top: rem(32);
      font-weight: 500;
      line-height: rem(24);
      margin-bottom: rem(8);
    }
  }

  .p {

    @include mq($until: tablet) {
      font-size: rem(14);
      line-height: rem(20);
    }
  }

  .carousel--boxed & {
    padding: rem(24) rem(40) rem(56);
    border-radius: rem($radius);
    overflow: hidden;

    @include mq(tablet) {
      padding-right: rem(128);
      padding-left: rem(128);
    }

    @include mq(desktop) {
      padding-right: rem(48);
      padding-left: rem(48);
    }
  }

  .carousel--small & {
    padding-right: rem(64);
    padding-left: rem(64);
  }

}

.carousel__arrow_small {
  width: rem(10);
  height: rem(10);
  position: absolute;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  transition: opacity duration();

  &--prev {
    left: rem(0);
    transform: translateY(-50%) rotate(-45deg) skew(-20deg, -20deg);
    border-top: solid 2px currentColor;
    border-left: solid 2px currentColor;
  }

  &--next {
    right: rem(0);
    transform: translateY(-50%) rotate(45deg) skew(20deg, 20deg);
    border-top: solid 2px currentColor;
    border-right: solid 2px currentColor;
  }
}

.carousel__arrow {
  width: rem(14);
  height: rem(14);
  position: absolute;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  transition: opacity duration();

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }

  .carousel--large & {
    top: auto;
    bottom: rem(12);
    width: rem(12);
    height: rem(12);
  }
}

.carousel__arrow--prev {
  left: rem(24);
  transform: translateY(-50%) rotate(-45deg);
  border-top: solid 2px currentColor;
  border-left: solid 2px currentColor;

  @include mq(tablet) {
    left: rem(32);
  }

  .carousel--large & {
    @include mq(desktop) {
      left: rem(100);
    }
  }

}

.carousel__arrow--next {
  right: rem(24);
  transform: translateY(-50%) rotate(45deg);
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;

  @include mq(tablet) {
    right: rem(32);
  }

  .carousel--large & {
    @include mq(desktop) {
      right: rem(100);
    }
  }
}

.carousel__pagination {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  font-size: 0;
  text-align: inherit;

  .carousel--boxed & {
    bottom: rem(16);
  }

  .carousel--large & {
    text-align: center;
  }

  .carousel--small & {
    display: none;
  }

  & > .swiper-pagination-bullet {
    width: rem(32);
    height: rem(16);
    position: relative;
    background: none;
    border-radius: 0;
    opacity: 1;
    cursor: pointer;

    &:nth-child(n+2) {
      margin-left: rem(16);
    }

    &::before {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      transform-origin: 50% 100%;
      transform: scale(1, 0.5);
      will-change: transform;
      background-color: color('grey-light');
      transition-property: transform, background-color;
      transition-duration: duration();
    }

    &.swiper-pagination-bullet-active {
      cursor: default;

      &::before {
        transform: none;
        background-color: color('primary');
      }

      .carousel--boxed &::before {
        background-color: color('white');
      }
    }
  }
}
