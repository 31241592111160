@font-face {
  font-family: 'SkyText';
  font-weight: bold;
  font-style: normal;
  src:  url('#{$assets}/fonts/sky_bold.woff2') format('woff2'),
        url('#{$assets}/fonts/sky_bold.woff') format('woff');
}

@font-face {
  font-family: 'SkyText';
  font-weight: normal;
  font-style: italic;
  src:  url('#{$assets}/fonts/sky_italic.woff2') format('woff2'),
        url('#{$assets}/fonts/sky_italic.woff') format('woff');
}

@font-face {
  font-family: 'SkyText';
  font-weight: 500;
  font-style: normal;
  src:  url('#{$assets}/fonts/sky_med.woff2') format('woff2'),
        url('#{$assets}/fonts/sky_med.woff') format('woff');
}

@font-face {
  font-family: 'SkyText';
  font-weight: normal;
  font-style: normal;
  src:  url('#{$assets}/fonts/sky_reg.woff2') format('woff2'),
        url('#{$assets}/fonts/sky_reg.woff') format('woff');
}

html {
  font-family: 'SkyText';
  font-variant-ligatures: none;
  text-size-adjust: none;
  text-rendering: optimizeLegibility;
  color: color('black');
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: rem(16);
  line-height: 1;
}

h1, .h1 {
  font-size: rem(34);
  line-height: rem(40);

  @include mq(desktop) {
    font-size: rem(40);
    line-height: rem(48);
  }
}

h2, .h2 {
  font-size: rem(28);
  line-height: rem(40);

  @include mq(desktop) {
    font-size: rem(32);
    line-height: rem(48);
  }
}

h3, .h3 {
  font-size: rem(24);
  line-height: rem(32);
}

h4, .h4 {
  font-size: rem(24);
  font-weight: 500;
  line-height: rem(24);
}

h5, .h5 {
  font-size: rem(20);
  font-weight: 500;
  line-height: rem(32);
}

h6, .h6 {
  font-weight: 500;
}

p, .p {
  font-size: rem(17.6);
  line-height: rem(24);
}

.p-small, li {
  font-size: rem(17.6);
  line-height: rem(24);
}

.p-normal {
  font-size: rem(17.6);
  line-height: rem(24);
}

.p-big {
  font-size: rem(17.6);
  line-height: rem(24);

  &--interline {
    font-size: rem(18);
    line-height: rem(32);
  }
  &ger--interline {
    font-size: rem(22);
    line-height: rem(32);
  }
}

.interline-normal {
  line-height: normal !important;
}

.font-medium {
  font-weight: 500;
}

.c-primary {
  color: color('primary');
}

.c-secondary {
  color: color('secondary');
}

.c-white {
  color: color('white');
}

.c-grey {
  color: color('grey');
}

.c-grey\:dark {
  color: color('grey-dark');
}

.c-black {
  color: color('black');
}

@include framework-rules('txt', (
  "property": "text-align",
  "value": (
    "left": left,
    "right": right,
    "center": center
  ),
  "breakpoints": ("md")
));

a {
  text-decoration: none;
  color: inherit;
}

.medium {
  font-weight: 500;
}

strong, .bold {
  font-weight: bold;
}

em {
  font-style: italic;
}

small {
  font-size: rem(14);
}

.link {
  display: inline-flex;
  position: relative;

  &::after {
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    transform-origin: 100% 50%;
    transform: scale(0, 1);
    background-color: currentColor;
    opacity: .5;
    transition: transform duration();
  }

  &.active::after {
    transform-origin: 0% 50%;
    transform: none;
  }

  @media (pointer: fine) {
    &:hover::after {
      transform-origin: 0% 50%;
      transform: none;
    }
  }
}

ul.list-disc {
  margin-left: 30px;
  list-style-type: disc;
}
