.addressable-tv {

    @include mq($until: desktop) {

        .section--secondary {
            .col {
                text-align: center;
                br {
                    display: none;
                }
            }
        }


        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }

        .mobile-divisor {
            position: relative;
            width: 100% !important;
            &::after {
                position: absolute;
                content: '';
                // bottom: -4.5rem;
                bottom: 0;
                left: 50%;
                width: 90%;
                transform: translateX(-50%);
                height: 2px;
                background-color: #255CB9;
            }
        }

        .channels__container.mobile-divisor::after {
            width: 100%;
            bottom: -3rem;
        }

    }

    @include mq(desktop) {
        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }
    }

}


.channels {
    padding-top: 0;
    @include mq(desktop) {
        color: color('white');
    }
}



.channels__container {
    display: flex;
    // background-color: red;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    color: color('black');

    &:nth-child(1) {
        margin-bottom: 7rem;
    }

    .desktop & {
        width: 66%;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;


        & > * {
           display: grid;
           place-content: center;
           height: 157px;
        }
        & + .channels__container ~ .channels__container {
            margin-top: 5rem;
            h3 {
                position: relative;
                width: fit-content;
                // height: 100%;
                width: 17rem;
                // background-color: aqua;
                &::before {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 104px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #E0E0E0;
                    top: -90px;
                }
            }
        }

    }

    .mobile & {

        h3 {
            margin-top: rem(80);
            margin-bottom: rem(30);
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 340px;
                height: 1px;
                background-color: rgba($color: #BDBDBD, $alpha: 0.3);
                top: -2.2rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.channels__item {
    // background-color: yellow !important;
    width: clamp(250px,25vw,160px);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    img {
        width: auto;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
    }
    small {
        // all: unset;
        @extend .mt-2;
        @extend .p-small;
        @extend .c-grey;
    }
    small.c-white {
        @extend .c-white;
        font-size: 1.5rem;
    }
    @include mq(desktop) {
        &:nth-child(2)::before {
            position: absolute;
            content: '';
            width: 1px;
            height: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: rgba($color: #fff, $alpha: 0.3);
        }
    }
}




