.mouse {
  display: none;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  width: rem(20);
  height: rem(60);
  z-index: 3;

  @include mq(desktop) {
    display: block;
  }
}

.mouse-icon {
  width: rem(16);
  height: rem(26);
  border-radius: rem(14);
  transform: none;
  border: rem(2) solid  color("black");;
  top: rem(170);
  margin-left: rem(1);
}

.wheel {
  display: block;
  margin: rem(7) auto;
  background-color: color("black");
  position: relative;
  height: rem(6);
  width: rem(2);
  border: rem(1) solid  color("black");;
  border-radius: rem(8);
}

.mouse-arrow {
  display: block;
  transform: rotate(
      45deg
  );
  border-right: rem(2) solid  color("black");;
  border-bottom: rem(2) solid  color("black");;
  margin: 0 0 rem(6) rem(5);
  width: rem(9);
  height: rem(9);

  &:first-child {
    animation: mouse-scroll 1s infinite;
    animation-direction: alternate;
    margin-top: rem(1);
  }

  &:last-child {
    animation: mouse-scroll 1s infinite;
    margin-top: rem(-6);
    animation-delay: .2s;
    animation-direction: alternate;
  }
}

@keyframes mouse-scroll {
  0% {
    opacity:0
  }
  50% {
    opacity:.5
  }
  to {
    opacity:1
  }
}
