.steps-retarget__long-arrow-left {
  display: none;

  @include mq(desktop) {
    display: block;
    position: relative;
    width: rem(512);
    left: 35vw;
  }

  @include mq(desktop-large) {
    left: 37vw;
  }

  @include mq(wide) {
    left: rem(550);
  }
}

.steps-retarget__container {
  position: relative;
  max-width: rem(490);
  margin-right: auto;
  margin-left: auto;

  @include mq(desktop) {
    max-width: 100%;
    padding-top: rem(28);
  }
}

.steps-retarget__long-arrow-top {
  width: rem(140);
  position: absolute;
  right: 0;
  bottom: rem(200);

  @include mq(desktop) {
    display: none;
  }
}

.steps-retarget {
  text-align: center;

  @include mq(desktop) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}

.steps-retarget__step {
  text-align: center;
  margin-top: rem(32);
  display: flex;
  flex-direction: column;

  @include mq(desktop) {
    flex-direction: row;
    align-items: flex-start;
    flex-basis: rem(150);
    margin-top: 0;
  }
}

.steps-retarget__icon {
  background: linear-gradient(90deg, #0085FF 0%, #0044B6 100%);
  border-radius: rem(100);
  width: rem(160);
  height: rem(160);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: rem(24);

  @include mq(desktop) {
    width: rem(112);
    height: rem(112);
    border-radius: 50%;
  }
}

.steps-retarget__left {
  color: color('primary');
  margin-bottom: rem(16);
}


.steps-retarget__short-arrow {
  transform: rotate(90deg);

  @include mq(desktop) {
    transform: inherit;
    margin-top: rem(60);
  }
}



